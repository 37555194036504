
window.onload = function () {
  const getGreetings = () => {
    const date = new Date()
    const hours = date.getHours()
    if (hours < 5) {
      return 'Good night,'
    } else if (hours < 12) {
      return 'Good morning,'
    } else if (hours < 18) {
      return 'Good afternoon,'
    } else {
      return 'Good evening,'
    }
  }
  // Get the element to update with the greetings
  const greetingsElement = document.getElementById('greetings')
  // Update the greetings element with the current time on page load
  greetingsElement.innerText = getGreetings()
  // Later on, update the greetings element every 15 minutes
  window.setInterval(() => {
    greetingsElement.innerText = getGreetings()
  }, 1000 * 60 * 15)
}
